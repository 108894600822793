<template>
  <div class="showBoard flex justify-content-end flex-column w-100 h-100">
    <div class="boardHeader">
      <h2 class="courseName">{{ course.name }}</h2>
      <p class="grade" v-if="course.edu_years && course.edu_years[0]">
        {{ $t("g.Grade") }} :
        <span class="pr-1">{{ course.edu_years[0].name }}</span>
      </p>
    </div>

    <b-container fluid class="listsContainer">
      <b-row class="flex-row flex-nowrap">
        <ListPosts
          v-for="list in lists"
          :key="list.id"
          :list="list"
          @showReport="showReport"
        />
        <report-component
          :reportable-id="reportable_id"
          :reportable-type="reportable_type"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListPosts from "@/components/classrooms/boards/posts/listPosts/ListPosts.vue";
import ReportComponent from "@/components/shared/ReportComponent/index.vue";
import { BContainer, BRow } from "bootstrap-vue";

export default {
  name: "ShowBoard",
  components: {
    ReportComponent,
    ListPosts,
    BContainer,
    BRow,
  },
  data() {
    return {
      grade: "",
      course: {},
      lists: [],
      reportable_id: null,
      reportable_type: null,
    };
  },
  created() {
    this.getLists();
  },
  methods: {
    getLists() {
      this.$http
        .get(`/student/boards/${this.$route.params.id}`)
        .then((response) => {
          this.course = response.data.data.course;
          this.lists = response.data.data.lists;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showReport(report) {
      this.reportable_id = report.reportable_id;
      this.reportable_type = report.reportable_type;
      this.$bvModal.show("bv-modal-report");
    },
  },
};
</script>

<style lang="scss" src="./_showBoard.scss"></style>
